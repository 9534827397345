import ShopMenuFood from "components/order/ShopMenuFoodComponent";

import Shopinfo from "components/order/ShopInfoComponent";
import ShopReview from "components/order/ShopReview";

import "components/order/style/MenuIntro.css";
import "components/order/style/ShopMenuFood.css";
import "components/order/style/ShopMenuGrade.css";

// 가게 메뉴 탭 쪽 따로 컴포넌트로 뺌
const ShopMenuInfo = ({
  shopInfo,
  menuList,
  menuRef,
  changeTab,
  setChangeTab,
  isCustomTable,
  setNavNumber,
  basketClickEvent,
}) => {
  const addCart = () => {
    console.log("addCart");
    basketClickEvent();
  };

  return (
    <div className="shop-menu-info">
      
      {shopInfo?.c2_idx != 20 && (
       
        <>
        
          <ShopMenuFood
            shopInfo={shopInfo}
            menuRef={menuRef}
            menuList={menuList}
            isCustomTable={isCustomTable}
            setNavNumber={setNavNumber}
          />
        </>
      )}    
    
    </div>
  );
};

export default ShopMenuInfo;
