import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  callBas,
  serverErrorHandler,
  SHOP_C3_IDX_MAP,
  SHOP_CUSTOM_IMG_PATH,
  SHOP_IMG_PATH,
} from "App-config";
import {
  getShopList,
  addLike,
  delLike,
  setBasket,
  getBasketCount,
  setShopScrap,
  getBasket,
} from "service/OrderService";

import StarCnt from "components/common/StarCountComponent";
import ShopEmpty from "components/order/ShopEmpty";

import shopmenuTest from "src_assets/order_menu-test_img.png";
import shoptestimg from "src_assets/order_test_img.png";
import timeImg from "src_assets/date-time_b.png";

import "components/order/style/OrderShopList.css";

import { Tabs, Tab, MenuItem, Select } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { useCallback } from "react";
import { useRef } from "react";
import stateUtilityInstance from "../../TestClass";
import { throttleFunction } from "../../App-config";
import { NewLoading } from "components/common/NewLoading";

import { isKiikeyApp, IS_ANDROID, IS_IOS } from "App-config";

import adv52 from "src_assets/adv52.png";

import HeartIcon from "src_assets/icon/icon_heart.svg";
import HeartOnIcon from "src_assets/icon/icon_heart_on.svg";
import { ReactComponent as CartIcon } from "src_assets/icon/icon_cart.svg";
import { ReactComponent as ReviewIcon } from "src_assets/icon/icon_review.svg";

const ShopList = ({
  isMobile,
  searchKeyList,
  setIsLogin,
  setTitle,
  searchKey,
}) => {
  const navigate = useNavigate();

  let { state } = useLocation();

  if (searchKey) {
    state = searchKey;
    console.log("searchKey6789");
  }
  console.log(searchKey);
  console.log(searchKeyList);

  const [shopList, setShopList] = useState([]);

  const shopListClickEvent = (shop) => {
    if (shop.m_idx) {
      if (shop.m_is_soldout > 0) {
        window.alert("품절된 상품입니다.");
        return;
      }

      if (shop.c2_idx == "21") {
        window.alert("준비중입니다.");
      } else {
        navigate("/order/prod", { state: { shopInfo: shop } });
      }
    } else {
      navigate("/order/shop", { state: { shopInfo: shop } });
    }
  };
  //const [height, setHeight] = useState(window.innerHeight);
  // setHeight(window.innerHeight);
  // console.log('asdf'+height);

  // useEffect(() => {
  //   //console.log('이쁨앱 :'+isKiikeyApp);
  //   if (isKiikeyApp) {
  //     if (IS_IOS) {
  //       //window.webkit.messageHandlers.scriptHandler.postMessage(message);
  //     }
  //     if (IS_ANDROID) {
  //       // 안드로이드
  //       window.KiikeyWebView.hideNavBar(true);
  //     }
  //   }
  // }, []);

  var shopList_tmp;

  const set_shop_list = (data) => {
    shopList_tmp = data.map((value, index) => {
      getShopList(value)
        .then((res) => {
          if (res.success) {
            return {};
          }
        })
        .catch((err) => {
          console.error(err);
          setIsLogin(false);
          serverErrorHandler(err);
        });
    });
  };

  // useEffect(() => {
  //   if (state) {
  //     if (state.c3idx == SHOP_C3_IDX_MAP["jbg"]) {
  //       navigate("/order/jbg");
  //     }
  //     getShopList(state)
  //       .then((res) => {
  //         if (res.success) {
  //           console.dir(res);
  //           setShopList(res.data);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setIsLogin(false);
  //         navigate("/account/signin");
  //       });
  //   }
  // }, [state]);

  const [target, setTarget] = useState(0);

  const Category_list = ({ cate_list, c2_idx }) => {
    //console.dir(cate_list);
    const liComponent = cate_list?.map((cate, index) => (
      <li
        key={"cate_" + index}
        style={{ textDecoration: target == cate.c3_idx && "underline" }}
        onClick={(e) => {
          // <li key={'cate_'+index} style={ {BorderBottom:target == cate.c3_idx &&"1px solid red"}} onClick={(e) => {
          liClick(e, cate.c3idx);

          const state = {
            title: cate.c3_name,
            c3idx: 0,
            searchKey: "",
            is_product: cate.used_product,
          };

          getShopList(state)
            .then((res) => {
              if (res.success) {
                setShopList(res.data);
                setTarget(cate.c3_idx);
              }
            })
            .catch((err) => {
              console.error(err);
              setIsLogin(false);

              serverErrorHandler(err);
            });
        }}
      >
        {cate.c3_name}
      </li>
    ));

    return <ul>{liComponent}</ul>;
  };

  const styles = {
    tabs: {
      background: "#fff",
      zIndex: 1000,
      width: "100%",
    },
    slide: {
      padding: 15,
      minHeight: 100,
      color: "#fff",
    },
    slide1: {
      backgroundColor: "#FEA900",
    },
    slide2: {
      backgroundColor: "#B3DC4A",
    },
    slide3: {
      backgroundColor: "#6AC0FF",
    },
  };

  const AntTabs = withStyles({
    root: {
      minHeight: "38px",
      width: "100%",
    },
    indicator: {
      backgroundColor: "var(--kk-color-Ymain)",
      height: "0px",
    },
    tabRoot: {
      minHeight: "24px",
    },
  })(Tabs);
  stateUtilityInstance.setPropertyValue("dataloadTab", []);

  const MainScreen = ({ state }) => {
    //console.dir(height);

    const handleResize = () => {
      //console.log('리사이즈:' + window.innerHeight);

      let eles = document.querySelectorAll(".tabSection");

      /* for (let i = 0; i < eles.length; i++) {
        eles[i].style.height = window.innerHeight - 98 + "px";
      } */

      //document.querySelectorAll('.tabSection').style.height = (window.innerHeight - 98) + 'px';
      //console.log(document.getElementById('tabSection'));
      //console.log(document.querySelector('#tabSection').style.height);
    };

    useEffect(() => {
      const throttle = throttleFunction(handleResize, 300);
      window.addEventListener("resize", throttle);
      return () => {
        window.removeEventListener("resize", throttle);
      };
    }, []);

    const { tabComponenet, ori_index } = useMemo(() => {
      var ori_index = 0;
      const tab = state?.c3_list?.map((cate, index) => {
        if (
          cate.c3_idx ==
          stateUtilityInstance.getPropertyByName("selectedCateC3Index")
        ) {
          ori_index = index;
          stateUtilityInstance.setPropertyValue("selectedCateTabIndex", index);
          stateUtilityInstance.setMobileHeaderTitle(cate.c3_name, true);
        }
        return (
          <Tab
            label={cate.c3_name}
            value={index}
            style={{ minHeight: "fit-content" }}
          />
        );
      });

      // const tabv = state?.c3_list?.map(
      //   (cate, index) => {

      //     let table_name = "";
      //     if (state?.table_name) {
      //       table_name = state?.table_name;
      //     }

      //     return (
      //       <TabView
      //         data={{
      //           title: cate.c3_name,
      //           c3idx: cate.c3_idx,
      //           searchKey: "",
      //           is_product: cate.used_product,
      //           table_name: table_name,
      //         }}
      //       />
      //     );
      //   }
      //   //makeView({cate})
      // );
      return { tabComponenet: tab, ori_index };
    }, [state]);

    const [tabState, setTabState] = useState(
      stateUtilityInstance.getPropertyByName("selectedCateTabIndex")
    );

    const tabViewList = state?.c3_list?.map((cate, index) => {
      let table_name = "";
      if (state?.table_name) {
        table_name = state?.table_name;
      }

      return (
        <div>
          <TabView
            data={{
              title: cate.c3_name,
              c3idx: cate.c3_idx,
              searchKey: "",
              is_product: cate.used_product,
              table_name: table_name,
              brandCode: cate.brandCode,
            }}
            tabState={tabState}
            index={index}
          />
        </div>
      );
    });

    //console.log("tabview");
    //console.dir(tabView);

    //const layout = useWindowDimensions();
    console.log(
      "tabindex :" +
        stateUtilityInstance.getPropertyByName("selectedCateTabIndex")
    );

    const handleChange = (event, value) => {
      //window.scrollTo(0,0);
      //setTitle(state?.c3_list[value].c3_name)
      //ori_index = value;
      stateUtilityInstance.setPropertyValue(
        "selectedCateC3Index",
        state?.c3_list[value].c3_idx
      );
      stateUtilityInstance.setPropertyValue("selectedCateTabIndex", value);
      stateUtilityInstance.setMobileHeaderTitle(
        state?.c3_list[value].c3_name,
        true
      );
      setTabState(value);
    };

    const handleChangeIndex = (index) => {
      //window.scrollTo(0,0);
      //setTitle(state?.c3_list[index].c3_name)
      //console.dir(state);
      //console.dir(setTitle);
      //console.log(state?.c3_list[index].c3_name);
      //document.getElementById('mobileTitle').innerText = state?.c3_list[index].c3_name;
      //ori_index = index;

      stateUtilityInstance.setPropertyValue(
        "selectedCateC3Index",
        state?.c3_list[index].c3_idx
      );
      stateUtilityInstance.setPropertyValue("selectedCateTabIndex", index);
      stateUtilityInstance.setMobileHeaderTitle(state?.c3_list[index].c3_name);
      console.log(stateUtilityInstance.getPropertyByName("title"));
      setTabState(index);
    };

    const hashTagAddToLocalStorage = (searchKey) => {
      const searchHashTagList = localStorage.getItem("searchHashTagList");
      let newSearchHashTagList = [];
      if (searchHashTagList) {
        newSearchHashTagList = searchHashTagList.split(",");
      } else {
        localStorage.setItem("searchHashTagList", []);
      }

      if (searchKey) {
        newSearchHashTagList.unshift(searchKey);
        newSearchHashTagList = new Set(newSearchHashTagList);
        newSearchHashTagList = [...newSearchHashTagList];
        if (newSearchHashTagList.length > 4) {
          // 최대 4개
          newSearchHashTagList.pop();
        }
      }

      localStorage.setItem("searchHashTagList", newSearchHashTagList);

      //setHashTagList(newSearchHashTagList);
    };
    const gotoSearchKey = (searchKey) => {
      //if (!isMobile) {
      hashTagAddToLocalStorage(searchKey);
      navigate("/order/ShopSearch", {
        state: {
          title: "검색 결과",
          c3idx: 0,
          searchKey: searchKey,
          is_product: 0,
          searchKeyList: searchKeyList,
        },
      });
      //}
    };
    const searchOnChangeEvent = (e) => {
      if (e.keyCode == "13") {
        gotoSearchKey(e.target.value);
      } else if (e.type === "click") {
        gotoSearchKey(document.getElementById("order-search").value);
      }
    };

    //console.log(tabView)
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          className="order-search-box-wrap"
          style={{ height: "fit-content" }}
        >
          <div className="order-search-box">
            <input
              id="order-search"
              placeholder="브랜드나 상품명으로 검색하세요"
              onKeyUp={searchOnChangeEvent}
              /* onFocus={() => {
                if (isMobile) {
                  navigate("/order/ShopSearch", {
                    state: {
                      searchKeyList: searchKeyList,
                    },
                  });
                }

                //setIsFocus(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  //setIsFocus(false);
                }, 200);
              }} */
            />
          </div>
          <button
            className="order-search-button"
            onClick={searchOnChangeEvent}
          />
        </div>

        <div style={{ minHeight: "2px", background: "#BDBDBD" }}></div>

        <AntTabs
          value={tabState}
          onChange={handleChange}
          variant="scrollable"
          style={styles.tabs}
        >
          {tabComponenet}
        </AntTabs>

        <div style={{ minHeight: "2px", background: "#BDBDBD" }}></div>

        <img
          className="adv52Icon"
          alt=""
          src={adv52}
          style={{ position: "initial", padding: "10px 0 10px" }}
        />

        <div style={{ minHeight: "2px", background: "#BDBDBD" }}></div>

        <section
          className="tabSection tab-main-section"
          style={{ height: 0, flexGrow: 1, overflow: "auto" }}
        >
          <SwipeableViews
            index={tabState}
            onChangeIndex={handleChangeIndex}
            style={{ width: "100%" }}
            enableMouseEvents
          >
            {tabViewList}
          </SwipeableViews>
        </section>
      </div>
    );
  };

  const TabView = ({ data, tabState, index, setIsLogin }) => {
    const [shopList, setShopList] = useState(null);

    const VIEW_LIMIT = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const offset = (currentPage - 1) * VIEW_LIMIT;

    //const { prods, rows } = shopList;
    const prodsLimit = shopList?.prods.slice(0, offset + VIEW_LIMIT);
    const rowsLimit = shopList?.rows.slice(0, offset + VIEW_LIMIT);

    const loader = useRef();

    const handleObserver = useCallback(async (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setCurrentPage((currentPage) => currentPage + 1);
      }
    }, []);

    const options = {
      root: null,
      rootMargin: "100px", // 관찰하는 뷰포트의 마진 지정
      threshold: 1, // 관찰요소와 얼만큼 겹쳤을 때 콜백을 수행하도록 지정하는 요소
    };

    useEffect(() => {
      const observer = new IntersectionObserver(handleObserver, options);
      if (loader.current) observer.observe(loader.current);
      return () => observer.disconnect();
    }, [handleObserver, shopList]);

    useEffect(() => {
      if (data.searchKey) {
        stateUtilityInstance.setMobileHeaderTitle("검색 결과");
      }
    });
    // const [height,setHeight] = useState(window.innerHeight);

    // useEffect(()=>{
    //   console.log('리사이즈');
    // },[window.innerHeight])

    // const shopList = useMemo(async ()=> {
    //   if (data) {
    //     if (data.c3idx == SHOP_C3_IDX_MAP["jbg"]) {
    //       navigate("/order/jbg");
    //     }
    //     const result = await getShopList(data)
    //       .then((res) => {
    //         if (res.success) {
    //           console.log('shop데이터')
    //           return res.data;
    //         }
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //         setIsLogin(false);
    //         navigate("/account/signin");
    //       });

    //     //const {prods, rows} = result.data
    //     console.dir(result);
    //     setLoading(true);
    //     return result;
    //   }
    //   //return;
    // },[data]);

    //console.log(shopList)

    useEffect(() => {
      let dataloadTab = stateUtilityInstance.getPropertyByName("dataloadTab");
      // console.log('atatat');
      console.dir(dataloadTab);

      if (!dataloadTab.find((e) => e === data.c3idx)) {
        if (tabState <= index + 1 && tabState >= index - 1) {
          if (data) {
            if (data.c3idx == SHOP_C3_IDX_MAP["jbg"]) {
              navigate("/order/jbg");
            }
            getShopList(data)
              .then((res) => {
                if (res.success) {
                  let dataloadTab =
                    stateUtilityInstance.getPropertyByName("dataloadTab");
                  dataloadTab.push(data.c3idx);
                  stateUtilityInstance.setPropertyValue(
                    "dataloadTab",
                    dataloadTab
                  );
                  //console.log(data.c3idx);
                  //console.dir(res.data);
                  setShopList(res.data);
                }
              })
              .catch((err) => {
                console.error(err);
                setIsLogin(false);
                serverErrorHandler(err);
              });
          }
        }
      }
    }, [tabState]);

    // useEffect(()=>{
    //   orderHistoryListLimit = shopList.length > 0 && shopList.slice(0, offset + VIEW_LIMIT);
    //   console.log('orderHistoryListLimit');
    //   console.dir(orderHistoryListLimit);
    //   console.dir(shopList);
    // },[shopList]);

    //console.dir(data);

    const resultView = (
      <>
        <div className="order-shop-promotion" style={{ display: "none" }}>
          <p>프로모션</p>
        </div>

        {/* <div className="order-shop-restaurant">
          {data.is_product === "1"
            ? "상품"
            : data.table_name == ""
              ? "맛집"
              : "가게"}
        </div> */}
        <div className="sale-shop-list-area_none">
          <ul className="sale-shop-list">
            <li>
              <div className="shop-menu-img">
                <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
              </div>
              <span>본죽</span>
            </li>
            <li>
              <div className="shop-menu-img">
                <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
              </div>
              <span>버거킹</span>
            </li>
            <li>
              <div className="shop-menu-img">
                <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
              </div>
              <span>파파존스</span>
            </li>
            <li>
              <div className="shop-menu-img">
                <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
              </div>
              <span>파파존스</span>
            </li>
            <li>
              <div className="shop-menu-img">
                <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
              </div>
              <span>파파존스</span>
            </li>
          </ul>

          <ul className="shop-menu-index">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>

        <div className="shop-search-list">
          {shopList && (
            <>
              <ShopBranchComponent
                state={data}
                prods={prodsLimit}
                rows={rowsLimit}
                shopListClickEvent={shopListClickEvent}
                setIsLogin={setIsLogin}
              />
              {/* 페이지 로더 위치 */}
              <div
                className="LOADER"
                style={{ bottom: "300px", position: "relative" }}
                ref={loader}
              />
            </>
          )}
        </div>
      </>
    );

    return (
      <div className="tabSection" style={{ width: "100%" }}>
        {shopList ? (
          resultView
        ) : (
          <div>
            <NewLoading tabState={tabState} index={index} />
          </div>
        )}
        {/* 프로모션 이 없으면 아래 sale-shop-list-area -> sale-shop-list-area_none  */}
      </div>
    );
  };

  const liClick = (e, c3_idx) => {
    e.currentTarget.style.textDecoration = "underline";

    //setStyle(style)
  };

  const KeywordSearchScreen = ({ state, setIsLogin }) => {
    const handleResize = () => {
      let eles = document.querySelectorAll(".tabSection");
      /* for (let i = 0; i < eles.length; i++) {
        eles[i].style.height = window.innerHeight - 50 + "px";
      } */

      //document.querySelectorAll('.tabSection').style.height = (window.innerHeight - 98) + 'px';
      //console.log(document.getElementById('tabSection'));
      //console.log(document.querySelector('#tabSection').style.height);
    };

    useEffect(() => {
      const throttle = throttleFunction(handleResize, 300);
      window.addEventListener("resize", throttle);
      return () => {
        window.removeEventListener("resize", throttle);
      };
    }, [state]);

    return (
      <section
        className="tabSection"
        style={{ overflow: "scroll", height: window.innerHeight - 48 }}
      >
        <TabView
          data={{
            title: state.title,
            c3idx: state.c3idx,
            searchKey: state.searchKey,
            is_product: state.is_product,
          }}
          tabState={0}
          index={0}
          style={{ width: "100%" }}
          setIsLogin={setIsLogin}
        />
      </section>
    );
  };
  console.log(searchKey);
  console.log(state);
  const showEle = searchKey ? (
    <KeywordSearchScreen state={searchKey} setIsLogin={setIsLogin} />
  ) : state.searchKey == "" ? (
    <MainScreen state={state} />
  ) : (
    <KeywordSearchScreen state={state} setIsLogin={setIsLogin} />
  );

  //console.log(state.searchKey);
  //console.dir(KeywordSearchScreen);
  return showEle;
  //<MainScreen state={state} />

  // <section className="order-shop-list-frame">
  //   <div className="order-shop-title">{state?.title}</div>
  //   <div className="order-shop-nav"> {<Category_list cate_list={state?.c3_list}/>}</div>
  //   {/* 프로모션 이 없으면 아래 sale-shop-list-area -> sale-shop-list-area_none  */}
  //   <div className="sale-shop-list-area_none">
  //     <ul className="sale-shop-list">
  //       <li>
  //         <div className="shop-menu-img">
  //           <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
  //         </div>
  //         <span>본죽</span>
  //       </li>
  //       <li>
  //         <div className="shop-menu-img">
  //           <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
  //         </div>
  //         <span>버거킹</span>
  //       </li>
  //       <li>
  //         <div className="shop-menu-img">
  //           <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
  //         </div>
  //         <span>파파존스</span>
  //       </li>
  //       <li>
  //         <div className="shop-menu-img">
  //           <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
  //         </div>
  //         <span>파파존스</span>
  //       </li>
  //       <li>
  //         <div className="shop-menu-img">
  //           <img src={shopmenuTest} alt="샵 메뉴 이미지"></img>
  //         </div>
  //         <span>파파존스</span>
  //       </li>
  //     </ul>

  //     <ul className="shop-menu-index">
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //       <li></li>
  //     </ul>
  //   </div>

  //   <div className="shop-search-list">
  //     <div className="search-result">
  //       {state && state.searchKey && (
  //         <>
  //           <span>검색결과</span>
  //           <h3>{state.searchKey}</h3>
  //         </>
  //       )}
  //     </div>

  //     {Object.keys(shopList).length > 0 && (
  //       <ShopBranchComponent
  //         state={state}
  //         shopList={shopList}
  //         shopListClickEvent={shopListClickEvent}
  //       />
  //     )}
  //   </div>
  // </section>
};

const ShopBranchComponent = ({
  state,
  prods,
  rows,
  shopListClickEvent,
  setIsLogin,
}) => {
  return (
    <>
      {prods?.length > 0 && (
        <ShopMenuListComponent
          state={state}
          shopList={prods}
          shopListClickEvent={shopListClickEvent}
        />
      )}
      {rows?.length > 0 && (
        <ShopListComponent
          setIsLogin={setIsLogin}
          shopListClickEvent={shopListClickEvent}
          shopList={rows}
        />
      )}
      {prods?.length == 0 && rows?.length == 0 && <ShopEmpty />}
    </>
  );
};

const ShopMenuListComponent = ({
  state,
  shopList,
  shopListClickEvent,
  setIsLogin,
}) => {
  const navigate = useNavigate();
  const shopMenuListMap = shopList.map((shop, index) => {
    const iconSrc = shop.psid ? HeartOnIcon : HeartIcon;
    const activeAttribute = shop.psid ? { active: "" } : {};
    let tabname = "";
    if (shop.table_name) {
      tabname = shop.table_name.replace("_", "") + "/";
    }

    let parcelExt = "";
    let parcelFreeExt = "";

    if (shop.s_parcel_free_price > 0 && shop.s_parcel_price > 0) {
      parcelExt = <div>배송비 {shop.s_parcel_price}원</div>;
    } else if (shop.s_parcel_free_price > 0) {
      parcelFreeExt = (
        <div className="parcel_free_price">
          {shop.s_parcel_free_price}원 이상 무료 배송
        </div>
      );
    } else if (shop.s_parcel_price > 0) {
      parcelExt = `택배비 ${shop.s_parcel_price}원`;
    } else {
      parcelExt = <div>무료배송</div>;
    }

    let mPrice = "0";
    if (shop.m_price) {
      mPrice = shop.m_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    console.log(shop);
    const basketReload = () => {
      getBasket()
        .then((res) => {})
        .catch((err) => {
          console.error(err);
          serverErrorHandler(err);
        });
    };
    const basketAddClickEvent = async (e, flag) => {
      // 매장영업중인지 체크
      e.preventDefault();
      e.stopPropagation();

      // id가 countNumber인 p태그의 텍스트를 가져와서 숫자로 변환
      if (shop.m_is_soldout <= 0) {
        const countNumber = 1;

        //  const a = await basketDeleteAll();

        //if (await basketDeleteAll()) {

        const tabName = shop.table_name;
        const m_sIdx = shop.s_idx;
        const reqTemp = [
          {
            m_count: countNumber,
            m_idx: shop.m_idx,
            m_name: shop.m_name,
            m_price: shop.m_price * 1,
            optionCateArray: [],
            scheduleTime: undefined,
            totalPrice: 0,
            table_name: shop.table_name,
          },
        ];

        setBasket({ sIdx: m_sIdx, rows: reqTemp })
          .then((res) => {
            if (res.success && res.data.length >= 0) {
              getBasketCount().then((res) => {
                const bkCnt = Number(res.msg.cartnum);
                stateUtilityInstance.setBasketCount(bkCnt);
              });
            }

            callBas();

            if (flag) navigate("/order/confirm");
          })
          .catch((err) => {
            console.error(err);

            serverErrorHandler(err);
          });
        // } else {
        //   serverErrorHandler();
        // }
      }
    };
    const shopLikeClickEvent = (e, shop) => {
      e.preventDefault();
      e.stopPropagation();
      if (shop.m_is_soldout <= 0) {
        const target = e.currentTarget;
        if (target.hasAttribute("active")) {
          const state = {
            s_idx: shop.s_idx,
            m_idx: shop.m_idx,
            table_name: shop.table_name,
          };
          delLike(state)
            .then((res) => {
              if (res.success) {
                return {};
              }
            })
            .catch((err) => {
              console.error(err);
              setIsLogin(false);
              serverErrorHandler(err);
            });
          target.src = HeartIcon;
          target.removeAttribute("active");
        } else {
          const state = {
            s_idx: shop.s_idx,
            m_idx: shop.m_idx,
            table_name: shop.table_name,
          };
          addLike(state)
            .then((res) => {
              if (res.success) {
                return {};
              }
            })
            .catch((err) => {
              console.error(err);
              setIsLogin(false);
              serverErrorHandler(err);
            });
          target.src = HeartOnIcon;
          target.setAttribute("active", "");
        }
      }
    };
    return (
      <li
        key={index}
        onClick={() => {
          shopListClickEvent(shop);
        }}
      >
        <div className="shop-img">
          {shop.m_img ? (
            <img
              src={SHOP_CUSTOM_IMG_PATH + tabname + "menu/" + shop.m_img}
              alt="상품 메인 이미지"
              loading="lazy"
            />
          ) : (
            <img src={shoptestimg} alt="상품 메인 이미지" loading="lazy" />
          )}
          {shop.m_is_soldout > 0 && <p className="menu-preparing">일시품절</p>}
        </div>
        <ul className="shop-info">
          <li className="info-top">
            <div
              className="shop-name"
              style={{
                maxWidth:
                  shop.s_is_delivery + shop.s_is_packing + shop.s_is_parcel ==
                    0 && "90%",
              }}
            >
              {shop.m_name}
            </div>
            <img
              src={iconSrc}
              style={{ marginLeft: "0.5em" }}
              onClick={(e) => {
                shopLikeClickEvent(e, shop);
              }}
              {...activeAttribute}
            />
          </li>
          {/* <li className="shop-grade">
            <span>{mPrice}원</span>
            <CartIcon />
          </li> */}
          <li className="category-product-price">
            <div className="result-price">
              {/* <h3>18,000원</h3> */}
              <span>{mPrice}원</span>
              <span className="origin-price" style={{ display: "none" }}>
                {1000000}원
              </span>
            </div>

            <CartIcon
              style={{ marginLeft: "0.5em" }}
              onClick={(e) => {
                basketAddClickEvent(e, false);
              }}
            />
          </li>

          <li className="category-product-review">
            <div className="result-review">
              <div>
                <span style={{ color: "#F00" }}>★</span>
                0(0)
              </div>

              <div>구매(0)</div>
            </div>

            <ReviewIcon
              style={{ marginLeft: "0.5em" }}
              onClick={(e) => {
                // shopLikeClickEvent(e, shop);
              }}
            />
          </li>

          <li className="category-product-parcel-price">
            {parcelExt ? parcelExt : ""}
            {parcelFreeExt}
          </li>
        </ul>
        <div></div>
      </li>
    );
  });
  return <ul className="shop-list">{shopMenuListMap}</ul>;
};

const ShopListComponent = ({ setIsLogin, shopList, shopListClickEvent }) => {
  console.log(shopList);
  const shopListMap = shopList.map((shop, index) => {
    // if (index > 10)
    //   return;
    const iconSrc = shop.psid ? HeartOnIcon : HeartIcon;
    const activeAttribute = shop.psid ? { active: "" } : {};
    let reviewCnt = shop.total_cnt;
    if (!reviewCnt) {
      reviewCnt = 0;
    }

    let reviewStar = shop.total_star;
    if (!reviewStar) {
      reviewStar = 0;
    }

    let reviewAvg = Math.round(((reviewStar * 1) / (reviewCnt * 1)) * 2) / 2;
    if (!reviewAvg) {
      reviewAvg = 0;
    }
    const shopLikeClickEvent = (e, shop) => {
      e.preventDefault();
      e.stopPropagation();

      const target = e.currentTarget;
      if (target.hasAttribute("active")) {
        const state = {
          s_idx: shop.s_idx,
        };
        setShopScrap(state)
          .then((res) => {
            if (res.success) {
              return {};
            }
          })
          .catch((err) => {
            console.error(err);
            setIsLogin(false);
            serverErrorHandler(err);
          });
        target.src = HeartIcon;
        target.removeAttribute("active");
      } else {
        const state = {
          s_idx: shop.s_idx,
        };
        setShopScrap(state)
          .then((res) => {
            if (res.success) {
              return {};
            }
          })
          .catch((err) => {
            console.error(err);
            setIsLogin(false);
            serverErrorHandler(err);
          });
        target.src = HeartOnIcon;
        target.setAttribute("active", "");
      }
    };
    return (
      <li
        key={shop.s_idx + index}
        onClick={() => {
          shopListClickEvent(shop);
        }}
      >
        <div className="shop-img">
          {shop.s_img ? (
            <img
              src={SHOP_IMG_PATH + shop.s_img}
              alt="샵 메인 이미지"
              loading="lazy"
            />
          ) : (
            <img src={shoptestimg} alt="샵 메인 이미지" loading="lazy" />
          )}
          {shop.is_run < 1 && <p className="menu-preparing">준비중</p>}
        </div>
        <ul className="shop-info">
          <li className="info-top">
            <div
              className="shop-name"
              style={{
                maxWidth:
                  shop.s_is_delivery + shop.s_is_packing + shop.s_is_parcel ==
                    0 && "90%",
              }}
            >
              {shop.s_name}
            </div>
            <img
              src={iconSrc}
              style={{ marginLeft: "0.5em" }}
              onClick={(e) => {
                shopLikeClickEvent(e, shop);
              }}
              {...activeAttribute}
            />

            {/* <div className="shop-state">
              {shop.s_is_delivery > 0 && <span>포장</span>}
              {shop.s_is_packing > 0 && <span>배송</span>}
              {shop.s_is_parcel > 0 && <span>택배</span>}
            </div> */}
          </li>
          {shop?.c2_idx != 20 && shop?.c2_idx != 21 && (
            <>
              {/* <li className="shop-grade">
                <span>
                  <StarCnt isAvg={true} avg={reviewAvg} />
                </span>
                <p>{reviewAvg}</p>
                <p>({reviewCnt})</p>
              </li> */}
              <li className="category-product-review">
                <div className="result-review">
                  <div>
                    <span style={{ color: "#F00" }}>★</span>
                    0(0)
                  </div>

                  <div>구매(0)</div>
                </div>

                <ReviewIcon style={{ marginLeft: "0.5em" }} />
              </li>
              {/* <li className="shop-price">
                <span>
                  <img src={timeImg} alt="시간 이미지"></img>
                </span>
                <p>{shop.s_delivery_time}분 | </p>
                <p>
                  배송비 {shop.min_t_price}원 ~ {shop.max_t_price}원
                </p>
              </li> */}
            </>
          )}
          {shop?.c2_idx == 20 && (
            <li style={{ fontSize: 12, fontWeight: "600" }}>
              <br />
              {shop?.s_ad.split("\r\n").map((tag) => {
                return <p>{tag}</p>;
              })}
            </li>
          )}
        </ul>
      </li>
    );
  });
  return <ul className="shop-list">{shopListMap}</ul>;
};

export default ShopList;
