import { useEffect, useRef, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

import { getBasket, getCateList, selectPopKeyword } from "service/OrderService";

import ADBanner from "components/main/ADBannerComponent";
import Shop from "components/order/ShopComponent";
import ShopComponentJustInfo from "components/order/ShopComponentJustInfo";
import ShopList from "components/order/ShopListComponent";
import ShopCategory from "components/order/ShopCategoryComponent";
import OrderConfirm from "components/order/OrderConfirmComponent";
import Payment from "components/order/Payment";
import OrderWaiting from "components/order/OrderWaiting";
import LocationSet from "components/common/LocationSet";
import ShopComponentJangboja from "components/order/ShopComponentJangboja";
import ProductDetail from "components/order/product/ProductDetail";
import InputPayment from "components/order/InputPayment";
import InputOrderConfirm from "components/order/InputOrderConfirmComponent";
import "components/order/style/Ordering.css";
import Header from "components/common/Header";
import ShopSearchComponent from "../components/order/ShopSearchComponent";
import stateUtilityInstance from "../TestClass";
import { serverErrorHandler } from "App-config";
import line1 from "src_assets/line-1.svg";
import PaymentSuccessPage from "components/order/payment/PaymentSuccessPage";
const { goPay } = window;

const OrderRouter = ({
  isKiikeyApp,
  IS_IOS,
  IS_ANDROID,
  isMobile,
  isLogin,
  setIsLogin,
  user,
  playVideoEvent,
  closePopupEvent,
  closeGamePopupEvent,
  hiddenCatchGame,
}) => {
  console.log(window.location.search);
  const url = window.location.pathname;
  const mainContainerRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (mainContainerRef.current) mainContainerRef.current.scrollTo(0, 0);
  }, [pathname]);

  
  let searchKey;
  if (url.includes("/order/websearch")) {
    const urlParams = new URLSearchParams(window.location.search);

    //c3_idx = urlParams.get("c3_idx");
    //stateUtilityInstance.setPropertyValue("selectedCateC3Index", c3_idx);
    //table_name = urlParams.get("table_name");
    searchKey = urlParams.get("search_key");
    
  }
  if (url.includes("/order/webcata")) {
    var c3_idx = new URLSearchParams(window.location.search).get("c3_idx");
    stateUtilityInstance.setPropertyValue("selectedCateC3Index", c3_idx);
    var table_name = new URLSearchParams(window.location.search).get("table_name");
    console.log(table_name);
    console.log(c3_idx);
}
  const navigate = useNavigate();

  const params = useParams();

  const menuRef = useRef();

  const [userInfo, setUserInfo] = useState({});

  const [basketCount, setBasketCount] = useState(0);
  const [basketList, setBasketList] = useState({ sIdx: 0, rows: [] });

  const [totalAmt, setTotalAmt] = useState(0);

  const [cateList, setCateList] = useState([]);
  const [searchKeyList, setSearchKeyList] = useState([]);

  const [shopInfo, setShopInfo] = useState({});

  const [title, setTitle] = useState("");

  const categoryClickEvent = ({ data, c3data }) => {
    //console.log('data');
    console.dir(c3data);
    if (isKiikeyApp) {
      if (IS_IOS) {
        //window.webkit.messageHandlers.scriptHandler.postMessage(message);
      }
      if (IS_ANDROID) {
        // 안드로이드
        window.KiikeyWebView.hideNavBar(true);
      }
    }

    let table_name = "";
    if (data.table_name) {
      table_name = "_" + data.table_name;
      console.log(table_name);
    }
    //setTitle(data.c3_name);
    stateUtilityInstance.setPropertyValue("selectedCateC3Index", data.c3_idx);
    console.log(
      "setName:" + stateUtilityInstance.getPropertyByName("selectedCateC3Index")
    );
    console.log(data.c3_name);
    console.log(data.c3_idx);
    console.log(c3data);
    console.log(data.used_product);
    console.log(table_name);
    console.log(data.brandCode);

    navigate("/order/search", {
      state: {
        title: data.c3_name,
        c3idx: data.c3_idx,
        c3_list: c3data,
        searchKey: "",
        is_product: data.used_product,
        table_name: table_name,
        brandCode: data.brandCode,
      },
    });
  };

  useEffect(() => {
    selectPopKeyword()
      .then((res) => {
        if (res.success) {
          setSearchKeyList(res.data);
        }
      })
      .catch(console.error);

    getCateList()
      .then((res) => {
        if (res.data?.length > 0) {
          setCateList(res.data);
        }
      })
      .catch((err) => {
        console.error("cateList err", err);
        sessionStorage.clear();
        setIsLogin(false);
        serverErrorHandler(err);
      });

    basketReload();
  }, []);

  const basketReload = () => {
    getBasket()
      .then((res) => {
        if (res.success && res.data.length >= 0) {
          let bkCnt = 0;
          res.data.forEach((ele) => {
            ele.rows.forEach((row) => {
              bkCnt++;
            });
          });
          console.log("바스켓 카운트 :" + bkCnt);
          console.dir(res.data);

          if (bkCnt == 0) {
            stateUtilityInstance.setPropertyValue("basketShopIdx", 0);
          } else {
            stateUtilityInstance.setPropertyValue(
              "basketShopIdx",
              res.data[0].s_idx
            );
          }

          stateUtilityInstance.setBasketCount(bkCnt);
          setBasketCount(bkCnt);
        }
      })
      .catch((err) => {
        console.error(err);
        serverErrorHandler(err);
      });
  };

  return (
    <>
      {!isMobile && (
        <ADBanner
          isLogin={isLogin}
          user={user}
          playVideoEvent={playVideoEvent}
          closeGamePopupEvent={closeGamePopupEvent}
          hiddenCatchGame={hiddenCatchGame}
        />
      )}

      <section className="ordering">
        <div className="div-2" ref={mainContainerRef}>
          {isMobile && <Header />}
          {isMobile && !params["*"] && (
            <ShopCategory
              isMobile={isMobile}
              categoryClickEvent={categoryClickEvent}
              cateList={cateList}
              searchKeyList={searchKeyList}
              isKiikeyApp={isKiikeyApp}
              IS_IOS={IS_IOS}
              IS_ANDROID={IS_ANDROID}
              setTitle={setTitle}
            />
          )}
          {!isMobile && (
            <ShopCategory
              isMobile={isMobile}
              categoryClickEvent={categoryClickEvent}
              cateList={cateList}
              searchKeyList={searchKeyList}
            />
          )}
          <Routes>
            <Route
              path=""
              element={
                !isMobile && (
                  <Navigate
                    to="search"
                    state={{ title: "치킨", c3idx: 98, searchKey: "" }}
                  />
                )
              }
            />
            <Route
              path="websearch"
              element={
                isMobile && (
                  <Navigate
                    to="/order/ShopSearch"
                    state={{
                      title: "검색 결과",
                      c3idx: 0,
                      searchKey: searchKey,
                      is_product: 0,
                      searchKeyList: searchKeyList,
                    }}
                  />
                )
              }
            />
 <Route
              path="webcata"
              element={
                isMobile && (
                  <Navigate
                    to="/order/search"
                    state={{ title: "헤어", c3idx: 90, searchKey: "" , c3_list:[ 
                      {
                        "c3_idx": "91",
                        "c2_idx": "4",
                        "c3_name": "화장품",
                        "brandCode": null,
                        "c3_order": "2",
                        "c3_img": "91.png",
                        "c3_is_multi_img": "0",
                        "is_removed": "0",
                        "c3_datetime": "0000-00-00 00:00:00",
                        "tl_idx": "2",
                        "table_name": "cosmetics",
                        "used_cate_img": "0",
                        "used_schedule": "0",
                        "used_product": "1",
                        "review_title": "제품후기"
                    },
                    {
                      "c3_idx": "90",
                      "c2_idx": "4",
                      "c3_name": "헤어",
                      "brandCode": null,
                      "c3_order": "3",
                      "c3_img": "90.png",
                      "c3_is_multi_img": "0",
                      "is_removed": "0",
                      "c3_datetime": "0000-00-00 00:00:00",
                      "tl_idx": "3",
                      "table_name": "hair",
                      "used_cate_img": "1",
                      "used_schedule": "0",
                      "used_product": "0",
                      "review_title": "관리후기"
                  },
                  {
                    "c3_idx": "92",
                    "c2_idx": "4",
                    "c3_name": "건강식품",
                    "brandCode": null,
                    "c3_order": "10",
                    "c3_img": "115.png",
                    "c3_is_multi_img": "0",
                    "is_removed": "0",
                    "c3_datetime": "0000-00-00 00:00:00",
                    "tl_idx": "10",
                    "table_name": "cosmetics",
                    "used_cate_img": "0",
                    "used_schedule": "0",
                    "used_product": "1",
                    "review_title": "관리후기"
                },
                {
                  "c3_idx": "93",
                  "c2_idx": "4",
                  "c3_name": "행사상품",
                  "brandCode": null,
                  "c3_order": "11",
                  "c3_img": "49.png",
                  "c3_is_multi_img": "0",
                  "is_removed": "0",
                  "c3_datetime": "0000-00-00 00:00:00",
                  "tl_idx": "11",
                  "table_name": "cosmetics",
                  "used_cate_img": "0",
                  "used_schedule": "0",
                  "used_product": "1",
                  "review_title": "제품후기"
              }

                    ] ,
                    searchKey: "",
                    is_product: 0,
                    table_name: {table_name},
                    brandCode: null
                  }
                }
                  />
                )
              }
            />
            <Route
              path="search"
              element={
                <ShopList
                  isMobile={isMobile}
                  setIsLogin={setIsLogin}
                  searchKeyList={searchKeyList}
                  IS_IOS={IS_IOS}
                  IS_ANDROID={IS_ANDROID}
                  isKiikeyApp={isKiikeyApp}
                  setTitle={setTitle}
                />
              }
            />
            <Route
              path="ShopSearch"
              element={
                <ShopSearchComponent
                  isMobile={true}
                  searchKeyList={searchKeyList}
                  //isFocus = {isFocus}
                  //setIsFocus = {setIsFocus}
                />
              }
            />
            <Route
              path="shop/*"
              element={
                <Shop
                  isMobile={isMobile}
                  basketList={basketList}
                  setBasketList={setBasketList}
                  totalAmt={totalAmt}
                  setTotalAmt={setTotalAmt}
                  shopInfo={shopInfo}
                  setShopInfo={setShopInfo}
                  menuRef={menuRef}
                  basketCount={basketCount}
                  basketReload={basketReload}
                />
              }
            />

            <Route
              path="jbg"
              element={
                <ShopComponentJangboja
                  isMobile={isMobile}
                  shopInfo={shopInfo}
                  setShopInfo={setShopInfo}
                  basketList={basketList}
                  setBasketList={setBasketList}
                  totalAmt={totalAmt}
                  setTotalAmt={setTotalAmt}
                />
              }
            />

            <Route
              path="print"
              element={
                <ShopComponentJustInfo
                  isMobile={isMobile}
                  shopInfo={shopInfo}
                  setShopInfo={setShopInfo}
                  basketList={basketList}
                  setBasketList={setBasketList}
                  totalAmt={totalAmt}
                  setTotalAmt={setTotalAmt}
                />
              }
            />

            <Route
              path="prod"
              element={
                <ProductDetail
                  basketCount={basketCount}
                  basketReload={basketReload}
                  searchKeyList={searchKeyList}
                  isMobile={isMobile}
                />
              }
            />

            <Route
              path="confirm"
              element={
                <OrderConfirm
                  basketReload={basketReload}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              }
            />

            <Route
              path="inputConfirm"
              element={
                <InputOrderConfirm
                  basketReload={basketReload}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              }
            />

            <Route
              path="payment"
              element={
                <Payment
                  isMobile={isMobile}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              }
            />
            <Route
              path="inputPayment"
              element={
                <InputPayment
                  isMobile={isMobile}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              }
            />
            <Route
              path="payment/changeLocation"
              element={
                <LocationSet
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  redirectUrl={"/order/payment"}
                />
              }
            />

            {/* <Route path="payresult" element={<OrderWaiting />} /> */}

            <Route
              path="payresult"
              element={<PaymentSuccessPage userInfo={userInfo} />}
            />
          </Routes>
        </div>
      </section>
    </>
  );
};

export default OrderRouter;
